import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { UsuarioService } from '../services/usuario/usuario.service';
import { Router } from '@angular/router';
import { switchMap, catchError } from 'rxjs/operators';
import { EMPTY, Subject, Observable, timer, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material';
import { WhatIsNewModalComponent } from '../what-is-new-modal/what-is-new-modal.component';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent implements OnInit, OnDestroy {

  public unreadNotifications: number;
  public notifications: any = [];
  public notificationSwitch = false;

  private notificationQtySubscription: Subscription;
  private notificationQtySubject = new Subject();

  private actualizarNotificationSubscription: Subscription;
  private actualizarNotificationSubject = new Subject();

  private notificationSubscription: Subscription;
  private notificationSubject = new Subject();

  private timerSubscription: Subscription;

  constructor(
    private authenticationService: AuthenticationService,
    private usuarioService: UsuarioService,
    private router: Router,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    // Inicializar Streams.
    this.initQtyNotifications();
    this.initNotificationList();
    this.initActualizarNotification();

    // Inicializar Stream del Timer.
    const minutes = 1;
    this.timerSubscription = timer(0, minutes * 60000).subscribe(() => {
      this.notificationQtySubject.next();
    });
  }

  ngOnDestroy() {
    this.notificationSubscription.unsubscribe();
    this.notificationQtySubscription.unsubscribe();
    this.timerSubscription.unsubscribe();
  }

  /**
   * Obtener las notificaciones del usuario
   * y mostrarlas u ocultarlas
   */
  public initQtyNotifications() {
    this.notificationQtySubscription = this.notificationQtySubject.pipe(
      switchMap(
        () => this.usuarioService.obtenerCantidadNotificaciones().pipe(
          catchError(
            (err) => {
              console.error(err);
              return EMPTY;
            },
          ),
        ),
      ),
    ).subscribe(
      (res: any) => this.unreadNotifications = res['no_leidas'],
      (err: any) => console.error(err),
    );

    this.notificationQtySubject.next();
  }

  public initActualizarNotification() {
    this.actualizarNotificationSubscription = this.actualizarNotificationSubject.pipe(
      switchMap(
        () => this.usuarioService.actualizarNotificaciones().pipe(
          catchError(
            (err) => {
              console.error(err);
              return EMPTY;
            },
          ),
        ),
      ),
    ).subscribe(
      (res: any) => res,
      (err: any) => console.error(err),
    );
  }

  public initNotificationList() {
    this.notificationSubscription = this.notificationSubject.pipe(
      switchMap(
        () => this.usuarioService.obtenerNotificaciones().pipe(
          catchError(
            (err) => {
              console.error(err);
              return EMPTY;
            },
          ),
        ),
      ),
    ).subscribe(
      (res: any) => this.notifications = res,
      (err: any) => console.error(err),
    );
  }

  public obtenerNotifications(e) {
    // Obtener las notificaciones
    if (!this.notificationSwitch) {
      this.notificationSubject.next();
      this.actualizarNotificationSubject.next();
      this.unreadNotifications = 0;
    }


    // Mostrar u ocultar el DIV que las contiene
    this.notificationSwitch = !this.notificationSwitch;
    e.stopPropagation();
  }

  public closeNotificationWrap() {
    this.notificationSwitch = false;
  }

  public getUserFullName() {
    return this.usuarioService.getUserFullName();
  }

  public getUserRole() {
    return this.usuarioService.getUserRole();
  }

  public getUserLegajo() {
    return this.usuarioService.getUserLegajo();
  }

  public userIsFcoria() {
    if (this.usuarioService.getUserName().toLowerCase() === 'fcoria') {
      return true;
    }
    return false;
  }

  public switchFcoriaRole() {
    this.usuarioService.switchearRolFcoria().subscribe(
      (res: any) => {
        this.authenticationService.switchFcoriaUser(res);
        window.location.reload();
      },
      (err) => {
        console.error(err);
      },
    );
  }

  public categoryIsActive(category: string): boolean {
    if (this.router.url.includes(category)) {
      return true;
    }
    return false;
  }

  public itemIsActive(url: string): boolean {
    if (this.router.url === url) {
      return true;
    }
    return false;
  }

  public canSeeCategory(category: string): boolean {
    switch (category) {
      case 'solicitudes':
        return this.usuarioService.esSolicitante() || this.usuarioService.esAdministrador() ||
          this.usuarioService.esResolutor() || this.usuarioService.esLogistica() || this.usuarioService.esCoordinadorLogistica(); break;
      case 'configuracion':
        return this.usuarioService.esAdministrador(); break;
      case 'riego':
        return this.usuarioService.esSupervisorRiego(); break;
      default:
        return false;
    }
  }

  public logout() {
    this.authenticationService.logOut();
  }

  public esSolicitante(): boolean {
    return this.usuarioService.esSolicitante();
  }

  public abrirNoticias() {
    this.dialog.open(WhatIsNewModalComponent);
  }
}
