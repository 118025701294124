var CoreModule = /** @class */ (function () {
    function CoreModule(parentModule) {
        this.throwIfAlreadyLoaded(parentModule, 'CoreModule');
    }
    /**
     * Throws an error if a module has already been imported.
     * @param parentModule
     * @param moduleName
     */
    CoreModule.prototype.throwIfAlreadyLoaded = function (parentModule, moduleName) {
        if (parentModule) {
            throw new Error(moduleName + " has already been loaded. Import Core modules in the AppModule only.");
        }
    };
    return CoreModule;
}());
export { CoreModule };
