import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import * as i0 from "@angular/core";
import * as i1 from "../../services/authentication/authentication.service";
import * as i2 from "@angular/router";
var LoggedInGuard = /** @class */ (function () {
    function LoggedInGuard(authenticationService, router) {
        this.authenticationService = authenticationService;
        this.router = router;
    }
    LoggedInGuard.prototype.canActivate = function (next, state) {
        return this.checkUserIsLoggedIn();
    };
    LoggedInGuard.prototype.checkUserIsLoggedIn = function () {
        if (this.authenticationService.isLoggedIn()) {
            return true;
        }
        this.router.navigateByUrl('/login');
        return false;
    };
    LoggedInGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoggedInGuard_Factory() { return new LoggedInGuard(i0.ɵɵinject(i1.AuthenticationService), i0.ɵɵinject(i2.Router)); }, token: LoggedInGuard, providedIn: "root" });
    return LoggedInGuard;
}());
export { LoggedInGuard };
