<div id="login-page" class="container-fluid">
    <div class="row">
  
      <div id="login-box">
  
        <div class="login-item">
          <img class="login-logo" src="assets/images/logo-unagro-invertido.png" />
        </div>
        <hr>
  
        <form [formGroup]="resetpassForm">
         <!--  <div class="login-item">
            <mat-form-field appearance="fill">
              <mat-label>Password</mat-label>
              <span matPrefix>
                <mat-icon class="icon-valid" *ngIf="fieldValid('username')">person</mat-icon>
                <mat-icon class="icon-invalid" *ngIf="!fieldValid('username')">error</mat-icon>
              </span>
              <input matInput type="text" formControlName="password">
              <mat-error>Usuario inv&aacute;lido.</mat-error>
            </mat-form-field>
          </div> -->
  
          <div class="login-item">
            <mat-form-field appearance="fill">
              <mat-label>Ingrese nueva Contrase&ntilde;a</mat-label>
              <span matPrefix>
                <mat-icon class="icon-valid" *ngIf="fieldValid('password')">lock</mat-icon>
                <mat-icon class="icon-invalid" *ngIf="!fieldValid('password')">error</mat-icon>
              </span>
              <input matInput type="password" formControlName="password">
              <mat-error>Contrase&ntilde;a inv&aacute;lida.</mat-error>
            </mat-form-field>
          </div>
  
          <!-- <div class="login-item error-message" *ngIf="errorMessage && loginForm.pristine">
            {{errorMessage}}
          </div> -->
  
          <div class="login-item">
            <button mat-flat-button color="primary" [disabled]="!buttonEnabled() || showSpinner"
              (click)="send()">
              <span *ngIf="!showSpinner">Enviar</span>
              <span *ngIf="showSpinner">
                <mat-spinner [diameter]="24"></mat-spinner>
              </span>
            </button>
          </div>
  
        </form>
  
      </div>
    </div>
  
    <div>
      <p class="copyright">&copy; Aceitera General Deheza S.A. | 1948 - {{year}} | {{ version }}</p>
    </div>
  
  
  </div>




{{token}}

{{email}}
