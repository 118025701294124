import { version } from "./version.json";

export const environment = {
  production: true,
  BASE_URL: "https://solicitudesagd.com/api/",
  cookieDomain: ".solicitudesagd.com",
  version: `${version}`,
  reCaptchaKey: "6Le7S7QoAAAAAFRW6o5gCQivLNrLLYXzo-v3hf0a",
  // reCaptchaKey: "6LfAmMcoAAAAAGk_npna4jb_b96hm2ZH_GZ5fDhG",
  captcha: {
    //sitekey: "6LfAmMcoAAAAAPobN7wrdPoZ6f-laYDSTH3pnpmb",
    sitekey: "6Le7S7QoAAAAAHxvxzJwJFcLLCbKiDYBhvUjeL7J",
  },

};
