import { OnInit, OnDestroy } from '@angular/core';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { UsuarioService } from '../services/usuario/usuario.service';
import { Router } from '@angular/router';
import { switchMap, catchError } from 'rxjs/operators';
import { EMPTY, Subject, timer, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material';
import { WhatIsNewModalComponent } from '../what-is-new-modal/what-is-new-modal.component';
var SideMenuComponent = /** @class */ (function () {
    function SideMenuComponent(authenticationService, usuarioService, router, dialog) {
        this.authenticationService = authenticationService;
        this.usuarioService = usuarioService;
        this.router = router;
        this.dialog = dialog;
        this.notifications = [];
        this.notificationSwitch = false;
        this.notificationQtySubject = new Subject();
        this.actualizarNotificationSubject = new Subject();
        this.notificationSubject = new Subject();
    }
    SideMenuComponent.prototype.ngOnInit = function () {
        var _this = this;
        // Inicializar Streams.
        this.initQtyNotifications();
        this.initNotificationList();
        this.initActualizarNotification();
        // Inicializar Stream del Timer.
        var minutes = 1;
        this.timerSubscription = timer(0, minutes * 60000).subscribe(function () {
            _this.notificationQtySubject.next();
        });
    };
    SideMenuComponent.prototype.ngOnDestroy = function () {
        this.notificationSubscription.unsubscribe();
        this.notificationQtySubscription.unsubscribe();
        this.timerSubscription.unsubscribe();
    };
    /**
     * Obtener las notificaciones del usuario
     * y mostrarlas u ocultarlas
     */
    SideMenuComponent.prototype.initQtyNotifications = function () {
        var _this = this;
        this.notificationQtySubscription = this.notificationQtySubject.pipe(switchMap(function () { return _this.usuarioService.obtenerCantidadNotificaciones().pipe(catchError(function (err) {
            console.error(err);
            return EMPTY;
        })); })).subscribe(function (res) { return _this.unreadNotifications = res['no_leidas']; }, function (err) { return console.error(err); });
        this.notificationQtySubject.next();
    };
    SideMenuComponent.prototype.initActualizarNotification = function () {
        var _this = this;
        this.actualizarNotificationSubscription = this.actualizarNotificationSubject.pipe(switchMap(function () { return _this.usuarioService.actualizarNotificaciones().pipe(catchError(function (err) {
            console.error(err);
            return EMPTY;
        })); })).subscribe(function (res) { return res; }, function (err) { return console.error(err); });
    };
    SideMenuComponent.prototype.initNotificationList = function () {
        var _this = this;
        this.notificationSubscription = this.notificationSubject.pipe(switchMap(function () { return _this.usuarioService.obtenerNotificaciones().pipe(catchError(function (err) {
            console.error(err);
            return EMPTY;
        })); })).subscribe(function (res) { return _this.notifications = res; }, function (err) { return console.error(err); });
    };
    SideMenuComponent.prototype.obtenerNotifications = function (e) {
        // Obtener las notificaciones
        if (!this.notificationSwitch) {
            this.notificationSubject.next();
            this.actualizarNotificationSubject.next();
            this.unreadNotifications = 0;
        }
        // Mostrar u ocultar el DIV que las contiene
        this.notificationSwitch = !this.notificationSwitch;
        e.stopPropagation();
    };
    SideMenuComponent.prototype.closeNotificationWrap = function () {
        this.notificationSwitch = false;
    };
    SideMenuComponent.prototype.getUserFullName = function () {
        return this.usuarioService.getUserFullName();
    };
    SideMenuComponent.prototype.getUserRole = function () {
        return this.usuarioService.getUserRole();
    };
    SideMenuComponent.prototype.getUserLegajo = function () {
        return this.usuarioService.getUserLegajo();
    };
    SideMenuComponent.prototype.userIsFcoria = function () {
        if (this.usuarioService.getUserName().toLowerCase() === 'fcoria') {
            return true;
        }
        return false;
    };
    SideMenuComponent.prototype.switchFcoriaRole = function () {
        var _this = this;
        this.usuarioService.switchearRolFcoria().subscribe(function (res) {
            _this.authenticationService.switchFcoriaUser(res);
            window.location.reload();
        }, function (err) {
            console.error(err);
        });
    };
    SideMenuComponent.prototype.categoryIsActive = function (category) {
        if (this.router.url.includes(category)) {
            return true;
        }
        return false;
    };
    SideMenuComponent.prototype.itemIsActive = function (url) {
        if (this.router.url === url) {
            return true;
        }
        return false;
    };
    SideMenuComponent.prototype.canSeeCategory = function (category) {
        switch (category) {
            case 'solicitudes':
                return this.usuarioService.esSolicitante() || this.usuarioService.esAdministrador() ||
                    this.usuarioService.esResolutor() || this.usuarioService.esLogistica() || this.usuarioService.esCoordinadorLogistica();
                break;
            case 'configuracion':
                return this.usuarioService.esAdministrador();
                break;
            case 'riego':
                return this.usuarioService.esSupervisorRiego();
                break;
            default:
                return false;
        }
    };
    SideMenuComponent.prototype.logout = function () {
        this.authenticationService.logOut();
    };
    SideMenuComponent.prototype.esSolicitante = function () {
        return this.usuarioService.esSolicitante();
    };
    SideMenuComponent.prototype.abrirNoticias = function () {
        this.dialog.open(WhatIsNewModalComponent);
    };
    return SideMenuComponent;
}());
export { SideMenuComponent };
