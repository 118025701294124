import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UsuarioService } from './../../../_core/services/usuario/usuario.service';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { environment } from '../../../../environments/environment.dev';
import { MatSnackBar } from '@angular/material';
var ResetPassComponent = /** @class */ (function () {
    function ResetPassComponent(activateRouter, router, usuarioService, formBuilder, snackBar) {
        this.activateRouter = activateRouter;
        this.router = router;
        this.usuarioService = usuarioService;
        this.formBuilder = formBuilder;
        this.snackBar = snackBar;
        this.version = environment.version;
        this.year = new Date().getFullYear();
        this.token = '';
        this.email = '';
        this.token = this.activateRouter.snapshot.params.Token;
        this.email = this.activateRouter.snapshot.params.Email;
        this.createResetpassForm();
    }
    ResetPassComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.showSpinner = false;
        this.usuarioService.verificarUser(this.token, this.email)
            .subscribe(function (resp) {
            if (resp != 'Autorizado') {
                _this.snackBar.open(resp.toString(), 'aceptar', {
                    duration: 5000
                });
                setTimeout(function () {
                    _this.router.navigateByUrl('/login');
                }, 5000);
            }
        });
    };
    ResetPassComponent.prototype.createResetpassForm = function () {
        this.resetpassForm = this.formBuilder.group({
            password: new FormControl(null, Validators.compose([
                Validators.required,
                Validators.minLength(8),
                Validators.maxLength(64),
            ]))
        });
    };
    ResetPassComponent.prototype.fieldValid = function (fieldName) {
        var field = this.resetpassForm.controls[fieldName];
        if (field.valid || field.untouched) {
            return true;
        }
        return false;
    };
    ResetPassComponent.prototype.send = function () {
        var _this = this;
        this.usuarioService.resetPassword(this.email, this.resetpassForm.controls['password'].value)
            .subscribe(function (resp) {
            if (resp) {
                _this.snackBar.open('Cambio exitoso, redirecionando a login', 'aceptar', {
                    duration: 5000
                });
                setTimeout(function () {
                    _this.router.navigateByUrl('/login');
                }, 5000);
            }
        });
    };
    ResetPassComponent.prototype.buttonEnabled = function () {
        return this.resetpassForm.valid && this.resetpassForm.dirty;
    };
    return ResetPassComponent;
}());
export { ResetPassComponent };
