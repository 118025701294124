<div class="modal-header">
  <h1 class="modal-title">
    Bienvenido a la versión {{version}}
  </h1>

  <!-- Botón Cerrar -->
  <div class="close" mat-dialog-close>
    <mat-icon aria-label="Cerrar Ventana" [inline]="true">close</mat-icon>
  </div>
  <!-- /Botón Cerrar -->
</div>

<div class="modal-wrapper">
  <h3><i class="material-icons">important_devices</i>Principales Novedades:</h3>
  <ul>
    <li>Los usuarios de rol supervisor ahora tienen un dashboard de métricas con los siguientes widgets:</li>
    <ul>
      <li>Operaciones de riego activas.</li>
      <li>Operaciones de riego finalizadas.</li>
      <li>Operaciones de riego certificadas.</li>
      <li>M<sup>3</sup> regados por semana.</li>
      <li>Tiempo en finca Santa Mónica.</li>
      <li>Planes de fertilización.</li>
      <li>Permisos especiales vigentes.</li>
      <li>Insumos requeridos.</li>
    </ul>
    <li>Se implementó un sistema de notificaciones para los supervisores de riego.</li>
    <li>Se implementó un sistema de alertas para los supervisores de riego.</li>
    <li>Se añadió la opción de asignar un número de convenio a los lotes.</li>
    <li>Se añado una columna de número de convenio a la tabla de lotes.</li>
    <li>Se añadió la opción de asignar un número de convenio las operaciones de riego.</li>
    <li>Los pedidos de insumos agropecuarios ahora muestran fecha de creación.</li>
    <li>Al colocar el cursor sobre un pedido de insumos se muestran los insumos pedidos y sus cantidades.</li>
    <li>Los pedidos de insumos agropecuarios muestran a quienes fueron derivados.</li>
    <li>Correción de bugs y mantenimiento en general.</li>
  </ul>

  <!--
  <h3><i class="material-icons">build</i>Correcciones de errores:</h3>
  <ul>
  </ul>
  -->
</div>

<!-- Modal Footer -->
<div class="modal-footer">
  <div class="row">
    <div class="col-md-12 text-center">

        <button mat-flat-button color="primary" (click)="cerrar()">
          Cerrar
        </button>

    </div>
  </div>
</div>
<!-- Modal Footer -->
