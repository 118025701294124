import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {UsuarioService} from '../../services/usuario/usuario.service';

@Injectable({
  providedIn: 'root',
})
/**
 * Guard de solicitudes
 */
export class SolicitudesUsersGuard implements CanActivate {

  constructor(
    private usuarioService: UsuarioService,
    public router: Router,
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canAccessSolicitudesModule();
  }

  canAccessSolicitudesModule(): boolean {
    const allowedRoles = ['Solicitante', 'Administrador', 'Resolutor', 'Logistica', 'Supervisor', 'Coordinador de Logistica'];

    if (allowedRoles.indexOf(this.usuarioService.getUserRole()) !== -1) {
      return true;
    }

    this.router.navigateByUrl('/riego');
    return false;
  }
}
