import { HttpService } from "../../http/http.service";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { MatDialog } from "@angular/material/dialog";
import { environment } from "./../../../../environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "../../http/http.service";
import * as i2 from "ngx-cookie-service";
import * as i3 from "@angular/router";
import * as i4 from "@angular/material/dialog";
var AuthenticationService = /** @class */ (function () {
    function AuthenticationService(http, cookieService, router, dialog) {
        this.http = http;
        this.cookieService = cookieService;
        this.router = router;
        this.dialog = dialog;
        this.user = null;
        this.loadUser();
    }
    AuthenticationService.prototype.login = function (username, password, recaptcha) {
        var mobile = false;
        var body = { username: username, password: password, recaptcha: recaptcha, mobile: mobile };
        return this.http.post("usuarios/login", body);
    };
    /**
     * ?FUNCTION PARA VALIDAR SI EL USUARIO DEBE CAMBIAR LA CONTRASEÑA
     */
    AuthenticationService.prototype.valideChangePass = function (username) {
        var body = { user: username };
        return this.http.post("usuarios/estadoPass", body);
    };
    /**
     * setUser
     * @param user Server user response.
     */
    AuthenticationService.prototype.setUser = function (userData) {
        this.user = userData.user;
        var user = JSON.stringify(userData.user);
        var token = userData.token;
        var refreshToken = userData.refreshToken;
        this.cookieService.set("user", user, undefined, "/", environment.cookieDomain, false);
        this.cookieService.set("token", token, undefined, "/", environment.cookieDomain, false);
        this.cookieService.set("refreshToken", refreshToken, undefined, "/", environment.cookieDomain, false);
        /** Set Authorization Header for all requests. */
        this.http.setHeader("Authorization", "Bearer " + token);
    };
    AuthenticationService.prototype.switchFcoriaUser = function (userData) {
        this.user = userData.user;
        var user = JSON.stringify(userData.user);
        this.cookieService.set("user", user, undefined, "/", environment.cookieDomain, false);
    };
    /**
     * loadUser
     */
    AuthenticationService.prototype.loadUser = function () {
        // TODO: Secure Cookies against CSRF.
        var user = this.cookieService.get("user"); //localStorage.getItem('user') //
        var token = this.cookieService.get("token"); //localStorage.getItem('token')//
        var refreshToken = this.cookieService.get("refreshToken"); //localStorage.getItem('refreshToken')
        if (user && token && refreshToken) {
            try {
                this.user = JSON.parse(user);
            }
            catch (err) {
                this.logOut();
                return;
            }
            // Set Authorization Header for all requests.
            this.http.setHeader("Authorization", "Bearer " + token);
        }
    };
    /**
     * isLoggedIn
     */
    AuthenticationService.prototype.isLoggedIn = function () {
        if (this.user) {
            return true;
        }
        return false;
    };
    AuthenticationService.prototype.getRefreshToken = function () {
        return this.cookieService.get("refreshToken"); //localStorage.getItem('refreshToken')//
    };
    /**
     * refreshToken
     */
    AuthenticationService.prototype.refreshToken = function () {
        var body = {
            refreshToken: this.getRefreshToken(),
        };
        return this.http.post("usuarios/refrescar-token", body);
    };
    AuthenticationService.prototype.updateTokens = function (tokens) {
        var token = tokens.token;
        var refreshToken = tokens.refreshToken;
        this.cookieService.set("token", token, undefined, "/", environment.cookieDomain, false);
        this.cookieService.set("refreshToken", refreshToken, undefined, "/", environment.cookieDomain, false);
        localStorage.setItem("token", token);
        localStorage.setItem("refreshToken", refreshToken);
        //localStorage.setItem('token', token);
        //localStorage.setItem('refreshToken', refreshToken);
        // Set Authorization Header for all requests.
        this.http.setHeader("Authorization", "Bearer " + token);
    };
    /**
     * logOut
     */
    AuthenticationService.prototype.logOut = function () {
        this.dialog.closeAll();
        this.user = null;
        this.cookieService.delete("user");
        this.cookieService.delete("token");
        this.cookieService.delete("refreshToken");
        this.cookieService.deleteAll("/", environment.cookieDomain);
        //localStorage.clear();
        this.router.navigateByUrl("/");
    };
    AuthenticationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthenticationService_Factory() { return new AuthenticationService(i0.ɵɵinject(i1.HttpService), i0.ɵɵinject(i2.CookieService), i0.ɵɵinject(i3.Router), i0.ɵɵinject(i4.MatDialog)); }, token: AuthenticationService, providedIn: "root" });
    return AuthenticationService;
}());
export { AuthenticationService };
