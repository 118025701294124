import { Routes } from '@angular/router';
import { LoggedInGuard } from './_core/guards/logged-in/logged-in.guard';
import { SolicitudesUsersGuard } from './_core/guards/solicitudes-users/solicitudes-users.guard';
import { RiegoUsersGuard } from './_core/guards/riego-users/riego-users.guard';
import { ResetPassComponent } from './_common/components/reset-pass/reset-pass.component';
var routes = [
    {
        path: '',
        loadChildren: './_common/app-common.module#AppCommonModule',
    },
    {
        path: 'solicitudes',
        canActivate: [LoggedInGuard, SolicitudesUsersGuard],
        loadChildren: './_solicitudes/solicitudes.module#SolicitudesModule',
    },
    {
        path: 'riego',
        canActivate: [LoggedInGuard, RiegoUsersGuard],
        loadChildren: './_riego/riego.module#RiegoModule',
    },
    {
        path: 'configuracion',
        canActivate: [LoggedInGuard, SolicitudesUsersGuard],
        loadChildren: './_configuracion/configuracion.module#ConfiguracionModule',
    },
    {
        path: 'reset-pass/:Token/:Email',
        component: ResetPassComponent
    },
    {
        path: '**',
        redirectTo: 'login',
    },
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
