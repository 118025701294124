import { Injectable } from '@angular/core';
import { HttpService } from '../../http/http.service';
import { environment } from '../../../../environments/environment';
import { HttpParams } from '@angular/common/http';
import { forkJoin } from 'rxjs/observable/forkJoin';
import { AuthenticationService } from '../authentication/authentication.service';

export const API_URL = environment.BASE_URL;

@Injectable({
  providedIn: 'root',
})
export class UsuarioService {
  constructor(
    private httpClient: HttpService,
    private authenticationService: AuthenticationService,
  ) { }

  public subirAvatar = function (avatar) {
    return this.httpClient.post('usuarios/subir-avatar', { avatar });
  };

  public listadoUsuarios = function (filtro) {
    return this.httpClient.get('usuarios/', filtro);
  };

  public obtenerUsuarios(count: number, page: number, orderby: string, order: string, filters: any, search: string) {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('orderby', orderby)
      .set('order', order)
      .set('search', search);

    if (count) {
      params = params.set('count', count.toString());
    }

    params = this.httpClient.agregarFiltrosParams(filters, params);

    return this.httpClient.get('usuarios', params);
  }

  public obtenerFiltrosUsuarios(columna) {
    return this.httpClient.get(`usuarios/filtros/${columna}`);
  }

  public modificarUsuario(idUsuario, datosUsuario: any) {
    return this.httpClient.put(`usuarios/${idUsuario}`, datosUsuario);
  }

  public obtenerRolesDisponibles() {
    return this.httpClient.get(`usuarios/roles`);
  }

  public crearUsuario(dataUsuario) {
    return this.httpClient.post(`usuarios`, dataUsuario);
  }

  /*
  public borrarUsuario(usuarioId) {
    return this.httpClient.delete(`usuarios/${usuarioId}`);
  }
  */

  public obtenerUsuario(usuarioId: number) {
    return forkJoin([
      this.httpClient.get(`usuarios/${usuarioId}`),
      this.httpClient.get(`usuarios/roles`),
    ]);
  }

  public recoverPassword(email: string){
    return this.httpClient.post(`usuarios/recoverPass`, {'email': email})
  }

  public resetPassword (email:string, clave:string){
    return this.httpClient.post(`usuarios/resetPass`, {'email': email, 'clave': clave})
  }

  public obtenerSupervisores() {
    return this.httpClient.get(`usuarios/supervisores`);
  }

  public obtenerRegadores() {
    return this.httpClient.get(`usuarios/regadores`);
  }

  public obtenerSupervisoresUsuario(usuarioId) {
    return this.httpClient.get(`usuarios/${usuarioId}/supervisor`);
  }

  public quitarSupervisor(usuarioId, relacionId) {
    return this.httpClient.delete(`usuarios/${usuarioId}/supervisor/${relacionId}`);
  }

  public asignarSupervisor(usuarioId, supervisorId) {
    return this.httpClient.post(`usuarios/${usuarioId}/supervisor`, { supervisorId });
  }

  public switchearRolFcoria() {
    return this.httpClient.put(`usuarios/fcoria/rol`, {});
  }

  public getUserFullName(): string {
    return this.authenticationService.user.nombre;
  }

  /**
   * ?obtener el valor de usuario de la BD
   */
  public getUser(): string {
    return this.authenticationService.user.usuario;
  }
  /**
   * ?obtener el valor de id usuario de la BD
   */
  public getIdUser(): Number {
    return this.authenticationService.user.id;
  }

  public getUserRole(): string {
    return this.authenticationService.user.rol.descripcion;
  }

  public getUserLegajo(): string {
    return this.authenticationService.user.legajo;
  }

  public getUserName(): string {
    return this.authenticationService.user.nombre;
  }

  public obtenerNotificaciones() {
    return this.httpClient.get(`notificaciones`);
  }

  public actualizarNotificaciones() {
    return this.httpClient.get(`notificaciones/actualizar`);
  }

  public obtenerCantidadNotificaciones() {
    return this.httpClient.get(`notificaciones/count`);
  }

  public obtenerRol() {
    return this.authenticationService.user.rol.descripcion;
  }
//TODO: cambiar por ids
  public esSolicitante() {
    return this.authenticationService.user.rol.descripcion === 'Solicitante';
  }

  public esAdministrador() {
    return this.authenticationService.user.rol.descripcion === 'Administrador';
  }

  public esResolutor() {
    return this.authenticationService.user.rol.descripcion === 'Resolutor';
  }

  public esLogistica() {
    return this.authenticationService.user.rol.descripcion === 'Logistica';
  }

  public esCoordinadorLogistica() {
    return this.authenticationService.user.rol.descripcion === 'Coordinador de Logistica';
  }

  public esChofer() {
    return this.authenticationService.user.rol.descripcion === 'Chofer';
  }

  public esSupervisor() {
    return this.authenticationService.user.rol.descripcion === 'Supervisor';
  }

  public esSupervisorRiego() {
    return this.authenticationService.user.rol.descripcion === 'Supervisor de Riego';
  }

  public obtenerLegajo() {
    return this.authenticationService.user.legajo;
  }

  public verificarUser(token, email){
    return this.httpClient.post(`usuarios/userByEmail`, {token, email})
  }
}
