import { Injectable } from "@angular/core";
import { HttpService } from "../../http/http.service";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { Observable } from "rxjs";
import { MatDialog } from "@angular/material/dialog";

import { environment } from "./../../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  public user: any = null;

  constructor(
    public http: HttpService,
    public cookieService: CookieService,
    public router: Router,
    public dialog: MatDialog
  ) {
    this.loadUser();
  }

  public login(username: string, password: string, recaptcha?: string) {
    const mobile = false;
    const body = { username, password, recaptcha, mobile };

    return this.http.post("usuarios/login", body);
  }

  /**
   * ?FUNCTION PARA VALIDAR SI EL USUARIO DEBE CAMBIAR LA CONTRASEÑA
   */
  public valideChangePass(username: string) {
    const body = { user: username };
    return this.http.post("usuarios/estadoPass", body);
  }

  /**
   * setUser
   * @param user Server user response.
   */
  public setUser(userData: any): void {
    this.user = userData.user;

    const user = JSON.stringify(userData.user);
    const token = userData.token;
    const refreshToken = userData.refreshToken;

    this.cookieService.set(
      "user",
      user,
      undefined,
      "/",
      environment.cookieDomain,
      false
    );
    this.cookieService.set(
      "token",
      token,
      undefined,
      "/",
      environment.cookieDomain,
      false
    );
    this.cookieService.set(
      "refreshToken",
      refreshToken,
      undefined,
      "/",
      environment.cookieDomain,
      false
    );

    /** Set Authorization Header for all requests. */
    this.http.setHeader("Authorization", `Bearer ${token}`);
  }

  public switchFcoriaUser(userData): void {
    this.user = userData.user;

    const user = JSON.stringify(userData.user);
    this.cookieService.set(
      "user",
      user,
      undefined,
      "/",
      environment.cookieDomain,
      false
    );
  }

  /**
   * loadUser
   */
  public loadUser(): void {
    // TODO: Secure Cookies against CSRF.

    const user = this.cookieService.get("user"); //localStorage.getItem('user') //
    const token = this.cookieService.get("token"); //localStorage.getItem('token')//
    const refreshToken = this.cookieService.get("refreshToken"); //localStorage.getItem('refreshToken')


    if (user && token && refreshToken) {
      try {
        this.user = JSON.parse(user);
      } catch (err) {
        this.logOut();
        return;
      }

      // Set Authorization Header for all requests.
      this.http.setHeader("Authorization", `Bearer ${token}`);
    }
  }

  /**
   * isLoggedIn
   */
  public isLoggedIn(): boolean {
    if (this.user) {
      return true;
    }

    return false;
  }

  public getRefreshToken(): string {
    return this.cookieService.get("refreshToken"); //localStorage.getItem('refreshToken')//

  }

  /**
   * refreshToken
   */
  public refreshToken(): Observable<any> {
    const body = {
      refreshToken: this.getRefreshToken(),
    };

    return this.http.post("usuarios/refrescar-token", body);
  }

  public updateTokens(tokens: any): void {
    const token = tokens.token;
    const refreshToken = tokens.refreshToken;

    this.cookieService.set(
      "token",
      token,
      undefined,
      "/",
      environment.cookieDomain,
      false
    );
    this.cookieService.set(
      "refreshToken",
      refreshToken,
      undefined,
      "/",
      environment.cookieDomain,
      false
    );

    localStorage.setItem("token", token);
    localStorage.setItem("refreshToken", refreshToken);

    //localStorage.setItem('token', token);
    //localStorage.setItem('refreshToken', refreshToken);


    // Set Authorization Header for all requests.
    this.http.setHeader("Authorization", `Bearer ${token}`);
  }

  /**
   * logOut
   */
  public logOut(): void {
    this.dialog.closeAll();
    this.user = null;
    this.cookieService.delete("user");
    this.cookieService.delete("token");
    this.cookieService.delete("refreshToken");
    this.cookieService.deleteAll("/", environment.cookieDomain);

    //localStorage.clear();

    this.router.navigateByUrl("/");

  }
}
