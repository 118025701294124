<div *ngIf="!isDesktop && isShow" class="mobileAdvice">
  <div class="mobileMsg">
    Para una mejor experiencia de usuario descargue la aplicación para su celular desde aquí: <a href="https://play.google.com/store/apps/details?id=ar.com.agd.unagro&hl=es">AGD Solicitudes</a>
  </div>
  <button (click)="toggleDisplay()">X</button>  
</div>

<mat-drawer-container id="wrapper" [hasBackdrop]="false">

  <mat-drawer [opened]="menuOpened" [mode]="'side'" *ngIf="!showSidebar()">
    <app-side-menu></app-side-menu>
  </mat-drawer>

  <mat-drawer-content>

    <div id="expand-button" (click)="toggleMenu()" *ngIf="!showSidebar()">
      <mat-icon [inline]="true">menu</mat-icon>
    </div>

    <router-outlet></router-outlet>

  </mat-drawer-content>

</mat-drawer-container>
