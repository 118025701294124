import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UsuarioService } from './../../../_core/services/usuario/usuario.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { environment } from '../../../../environments/environment.dev';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-reset-pass',
  templateUrl: './reset-pass.component.html',
  styleUrls: ['./reset-pass.component.scss']
})
export class ResetPassComponent implements OnInit {

  public resetpassForm: FormGroup;
  public showSpinner: boolean;
  public version = environment.version;
  public year: number = new Date().getFullYear();



  token = '';
  email = '';

  constructor(
    private activateRouter: ActivatedRoute,
    private router: Router,
    private usuarioService: UsuarioService,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    ) {
      this.token = this.activateRouter.snapshot.params.Token;
      this.email = this.activateRouter.snapshot.params.Email;
      this.createResetpassForm();

  }

  ngOnInit() {
    this.showSpinner = false;

    this.usuarioService.verificarUser(this.token, this.email)
    .subscribe((resp)=>{
      if(resp != 'Autorizado'){
        this.snackBar.open(resp.toString(), 'aceptar', {
          duration: 5000
      })

      setTimeout(() => {
        this.router.navigateByUrl('/login');
      }, 5000);
      }
    })

  }

  private createResetpassForm(){
    this.resetpassForm = this.formBuilder.group({
      password: new FormControl(null, Validators.compose([
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(64),
      ]))
    });
  }

  public fieldValid(fieldName: string): boolean {
    const field = this.resetpassForm.controls[fieldName];

    if (field.valid || field.untouched) {
      return true;
    }
    return false;
  }

  send(){
    this.usuarioService.resetPassword(this.email, this.resetpassForm.controls['password'].value)
    .subscribe(resp=>{
      if(resp){
        this.snackBar.open('Cambio exitoso, redirecionando a login', 'aceptar', {
          duration: 5000
      })
      setTimeout(() => {
        this.router.navigateByUrl('/login');
      }, 5000);
      }
    })
  }

  public buttonEnabled(): boolean {
    return this.resetpassForm.valid && this.resetpassForm.dirty;
  }
}
