import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {

  public menuOpened = true;
  public isDesktop = true;
  public isShow = false;

  constructor(
    private router: Router,
    private deviceService: DeviceDetectorService,
  ) {
    this.verifyDevice();
  }

  public verifyDevice() {
    this.isDesktop = this.deviceService.isDesktop();
    this.isShow = true;
  }

  public toggleDisplay() {
    this.isShow = false;
  }

  public toggleMenu(): void {
    this.menuOpened = !this.menuOpened;
  }

  public showSidebar(): boolean {
    if ((this.router.url === '/') || (this.router.url === '/login') || (this.router.url === '/404') || (this.router.url === '/mantenimiento') || (this.router.url.includes('/reset-pass/'))) {
      return true;
    }

    return false;
  }
}
