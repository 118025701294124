import { environment } from './../../../environments/environment';
import { MatDialogRef } from '@angular/material';
var WhatIsNewModalComponent = /** @class */ (function () {
    function WhatIsNewModalComponent(dialogRef) {
        this.dialogRef = dialogRef;
        this.version = environment.version;
    }
    WhatIsNewModalComponent.prototype.cerrar = function () {
        this.dialogRef.close();
    };
    return WhatIsNewModalComponent;
}());
export { WhatIsNewModalComponent };
