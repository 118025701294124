import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { SideMenuComponent } from './side-menu/side-menu.component';

import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBadgeModule } from '@angular/material/badge';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

import { RouterModule } from '@angular/router';

import { ClickOutsideModule } from 'ng-click-outside';
import { WhatIsNewModalComponent } from './what-is-new-modal/what-is-new-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';

@NgModule({
  declarations: [
    SideMenuComponent,
    WhatIsNewModalComponent,
  ],
  imports: [
    RouterModule,
    CommonModule,
    HttpClientModule,
    MatExpansionModule,
    MatIconModule,
    MatListModule,
    MatTooltipModule,
    MatBadgeModule,
    MatDialogModule,
    MatButtonModule,
    ClickOutsideModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
  ],
  providers: [
    CookieService,
  ],
  exports: [
    SideMenuComponent,
    WhatIsNewModalComponent,
  ],
  entryComponents: [
    WhatIsNewModalComponent,
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    this.throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  /**
   * Throws an error if a module has already been imported.
   * @param parentModule
   * @param moduleName
   */
  private throwIfAlreadyLoaded(parentModule: any, moduleName: string) {
    if (parentModule) {
      throw new Error(`${moduleName} has already been loaded. Import Core modules in the AppModule only.`);
    }
  }
}
