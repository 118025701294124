import { Router } from '@angular/router';
import { UsuarioService } from '../../services/usuario/usuario.service';
import * as i0 from "@angular/core";
import * as i1 from "../../services/usuario/usuario.service";
import * as i2 from "@angular/router";
var SolicitudesUsersGuard = /** @class */ (function () {
    function SolicitudesUsersGuard(usuarioService, router) {
        this.usuarioService = usuarioService;
        this.router = router;
    }
    SolicitudesUsersGuard.prototype.canActivate = function (next, state) {
        return this.canAccessSolicitudesModule();
    };
    SolicitudesUsersGuard.prototype.canAccessSolicitudesModule = function () {
        var allowedRoles = ['Solicitante', 'Administrador', 'Resolutor', 'Logistica', 'Supervisor', 'Coordinador de Logistica'];
        if (allowedRoles.indexOf(this.usuarioService.getUserRole()) !== -1) {
            return true;
        }
        this.router.navigateByUrl('/riego');
        return false;
    };
    SolicitudesUsersGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SolicitudesUsersGuard_Factory() { return new SolicitudesUsersGuard(i0.ɵɵinject(i1.UsuarioService), i0.ɵɵinject(i2.Router)); }, token: SolicitudesUsersGuard, providedIn: "root" });
    return SolicitudesUsersGuard;
}());
export { SolicitudesUsersGuard };
