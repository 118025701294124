import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
var AppComponent = /** @class */ (function () {
    function AppComponent(router, deviceService) {
        this.router = router;
        this.deviceService = deviceService;
        this.menuOpened = true;
        this.isDesktop = true;
        this.isShow = false;
        this.verifyDevice();
    }
    AppComponent.prototype.verifyDevice = function () {
        this.isDesktop = this.deviceService.isDesktop();
        this.isShow = true;
    };
    AppComponent.prototype.toggleDisplay = function () {
        this.isShow = false;
    };
    AppComponent.prototype.toggleMenu = function () {
        this.menuOpened = !this.menuOpened;
    };
    AppComponent.prototype.showSidebar = function () {
        if ((this.router.url === '/') || (this.router.url === '/login') || (this.router.url === '/404') || (this.router.url === '/mantenimiento') || (this.router.url.includes('/reset-pass/'))) {
            return true;
        }
        return false;
    };
    return AppComponent;
}());
export { AppComponent };
