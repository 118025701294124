import { Injectable } from "@angular/core";
import { HttpService } from "../http/http.service";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, switchMap, tap } from "rxjs/operators";
import { Router } from "@angular/router";
import { AuthenticationService } from "../services/authentication/authentication.service";
import { MatSnackBar } from "@angular/material";
import { MatDialog } from "@angular/material/dialog";

@Injectable({
  providedIn: "root",
})
/**
 * Interceptor para manejar solicitudes y respuestas
 */
export class InterceptorService {
  private secondTry = false;

  constructor(
    public api: HttpService,
    public router: Router,
    public authService: AuthenticationService,
    private toastController: MatSnackBar,
    public dialog: MatDialog
  ) {
    this.secondTry = false;
  }

  public refreshToken(req: HttpRequest<any>, next: HttpHandler) {
    return this.authService.refreshToken().pipe(
      switchMap((res: any) => {
        this.secondTry = false;
        this.authService.updateTokens(res);

        const headers = this.api.getHeaders();
        const newRequest = req.clone({ headers });
        return next.handle(newRequest);
      })
    );
  }

  public async presentToast(
    message: string,
    duration: number = 6000
  ): Promise<any> {
    this.toastController.open(message, "Entiendo", {
      duration: 3000,
      verticalPosition: "bottom",
      horizontalPosition: "center",
    });
  }

  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse && event.status === 200) {
          if (event.body.redirect == "true") {
            this.dialog.closeAll();
            this.router.navigate(["solicitudes"]);
          }
        }
      }),
      catchError((err: any) => {
        // No connection.
        if (err.status === 0) {
          this.dialog.closeAll();
          this.router.navigate(["mantenimiento"]);
          // this.presentToast('Error al conectarse con el servidor.');
          // return throwError(err);
        }

        // Unauthorized.
        if (err.status === 401 && this.router.url !== "/login") {
          if (!this.secondTry) {
            this.secondTry = true;
            return this.refreshToken(req, next);
          }

          this.presentToast("Su sesión há caducado.");
          this.authService.logOut();
          return throwError(err);
        }

        // Forbidden.
        if (err.status === 403) {
          this.presentToast("No tiene permisos para realizar esta acción.");
          return throwError(err);
        }

        // Server Error
        if (err.status === 500) {
          // Error de conexión con la base de datos
          if (
            typeof err.error === "string" &&
            err.error.indexOf("ECONNREFUSED") !== -1
          ) {
            this.dialog.closeAll();
            this.router.navigate(["mantenimiento"]);
          } else {
            this.presentToast(
              "Ocurrió un error en el servidor. Contacte a un Administador."
            );
            return throwError(err);
          }
        }

        // Server Not Available
        if (err.status === 503) {
          this.dialog.closeAll();
          this.router.navigate(["mantenimiento"]);

          // this.presentToast('El servidor se encuentra caído. Contacte a un Administador.');
          // return throwError(err);
        }

        return throwError(err);
      })
    );
  }
}
