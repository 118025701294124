import { HttpService } from '../../http/http.service';
import { environment } from '../../../../environments/environment';
import { HttpParams } from '@angular/common/http';
import { forkJoin } from 'rxjs/observable/forkJoin';
import { AuthenticationService } from '../authentication/authentication.service';
import * as i0 from "@angular/core";
import * as i1 from "../../http/http.service";
import * as i2 from "../authentication/authentication.service";
export var API_URL = environment.BASE_URL;
var UsuarioService = /** @class */ (function () {
    function UsuarioService(httpClient, authenticationService) {
        this.httpClient = httpClient;
        this.authenticationService = authenticationService;
        this.subirAvatar = function (avatar) {
            return this.httpClient.post('usuarios/subir-avatar', { avatar: avatar });
        };
        this.listadoUsuarios = function (filtro) {
            return this.httpClient.get('usuarios/', filtro);
        };
    }
    UsuarioService.prototype.obtenerUsuarios = function (count, page, orderby, order, filters, search) {
        var params = new HttpParams()
            .set('page', page.toString())
            .set('orderby', orderby)
            .set('order', order)
            .set('search', search);
        if (count) {
            params = params.set('count', count.toString());
        }
        params = this.httpClient.agregarFiltrosParams(filters, params);
        return this.httpClient.get('usuarios', params);
    };
    UsuarioService.prototype.obtenerFiltrosUsuarios = function (columna) {
        return this.httpClient.get("usuarios/filtros/" + columna);
    };
    UsuarioService.prototype.modificarUsuario = function (idUsuario, datosUsuario) {
        return this.httpClient.put("usuarios/" + idUsuario, datosUsuario);
    };
    UsuarioService.prototype.obtenerRolesDisponibles = function () {
        return this.httpClient.get("usuarios/roles");
    };
    UsuarioService.prototype.crearUsuario = function (dataUsuario) {
        return this.httpClient.post("usuarios", dataUsuario);
    };
    /*
    public borrarUsuario(usuarioId) {
      return this.httpClient.delete(`usuarios/${usuarioId}`);
    }
    */
    UsuarioService.prototype.obtenerUsuario = function (usuarioId) {
        return forkJoin([
            this.httpClient.get("usuarios/" + usuarioId),
            this.httpClient.get("usuarios/roles"),
        ]);
    };
    UsuarioService.prototype.recoverPassword = function (email) {
        return this.httpClient.post("usuarios/recoverPass", { 'email': email });
    };
    UsuarioService.prototype.resetPassword = function (email, clave) {
        return this.httpClient.post("usuarios/resetPass", { 'email': email, 'clave': clave });
    };
    UsuarioService.prototype.obtenerSupervisores = function () {
        return this.httpClient.get("usuarios/supervisores");
    };
    UsuarioService.prototype.obtenerRegadores = function () {
        return this.httpClient.get("usuarios/regadores");
    };
    UsuarioService.prototype.obtenerSupervisoresUsuario = function (usuarioId) {
        return this.httpClient.get("usuarios/" + usuarioId + "/supervisor");
    };
    UsuarioService.prototype.quitarSupervisor = function (usuarioId, relacionId) {
        return this.httpClient.delete("usuarios/" + usuarioId + "/supervisor/" + relacionId);
    };
    UsuarioService.prototype.asignarSupervisor = function (usuarioId, supervisorId) {
        return this.httpClient.post("usuarios/" + usuarioId + "/supervisor", { supervisorId: supervisorId });
    };
    UsuarioService.prototype.switchearRolFcoria = function () {
        return this.httpClient.put("usuarios/fcoria/rol", {});
    };
    UsuarioService.prototype.getUserFullName = function () {
        return this.authenticationService.user.nombre;
    };
    /**
     * ?obtener el valor de usuario de la BD
     */
    UsuarioService.prototype.getUser = function () {
        return this.authenticationService.user.usuario;
    };
    /**
     * ?obtener el valor de id usuario de la BD
     */
    UsuarioService.prototype.getIdUser = function () {
        return this.authenticationService.user.id;
    };
    UsuarioService.prototype.getUserRole = function () {
        return this.authenticationService.user.rol.descripcion;
    };
    UsuarioService.prototype.getUserLegajo = function () {
        return this.authenticationService.user.legajo;
    };
    UsuarioService.prototype.getUserName = function () {
        return this.authenticationService.user.nombre;
    };
    UsuarioService.prototype.obtenerNotificaciones = function () {
        return this.httpClient.get("notificaciones");
    };
    UsuarioService.prototype.actualizarNotificaciones = function () {
        return this.httpClient.get("notificaciones/actualizar");
    };
    UsuarioService.prototype.obtenerCantidadNotificaciones = function () {
        return this.httpClient.get("notificaciones/count");
    };
    UsuarioService.prototype.obtenerRol = function () {
        return this.authenticationService.user.rol.descripcion;
    };
    //TODO: cambiar por ids
    UsuarioService.prototype.esSolicitante = function () {
        return this.authenticationService.user.rol.descripcion === 'Solicitante';
    };
    UsuarioService.prototype.esAdministrador = function () {
        return this.authenticationService.user.rol.descripcion === 'Administrador';
    };
    UsuarioService.prototype.esResolutor = function () {
        return this.authenticationService.user.rol.descripcion === 'Resolutor';
    };
    UsuarioService.prototype.esLogistica = function () {
        return this.authenticationService.user.rol.descripcion === 'Logistica';
    };
    UsuarioService.prototype.esCoordinadorLogistica = function () {
        return this.authenticationService.user.rol.descripcion === 'Coordinador de Logistica';
    };
    UsuarioService.prototype.esChofer = function () {
        return this.authenticationService.user.rol.descripcion === 'Chofer';
    };
    UsuarioService.prototype.esSupervisor = function () {
        return this.authenticationService.user.rol.descripcion === 'Supervisor';
    };
    UsuarioService.prototype.esSupervisorRiego = function () {
        return this.authenticationService.user.rol.descripcion === 'Supervisor de Riego';
    };
    UsuarioService.prototype.obtenerLegajo = function () {
        return this.authenticationService.user.legajo;
    };
    UsuarioService.prototype.verificarUser = function (token, email) {
        return this.httpClient.post("usuarios/userByEmail", { token: token, email: email });
    };
    UsuarioService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UsuarioService_Factory() { return new UsuarioService(i0.ɵɵinject(i1.HttpService), i0.ɵɵinject(i2.AuthenticationService)); }, token: UsuarioService, providedIn: "root" });
    return UsuarioService;
}());
export { UsuarioService };
