import { version } from './version.json';

export const environment = {
  production: false,
  BASE_URL: 'http://192.168.1.75:8804/',
  cookieDomain: '.dicsys.com',
  version: `${version}@DEV`,
  reCaptchaKey: '6Lf2obUUAAAAAAR4MMIgyBuxt0a1gh7b4GMwACMr',
  captcha:{
		sitekey: '6Ldwz74eAAAAAHP9sjjdZ2uighEHgGvfCBUrEN_i'
	}
};
