import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UsuarioService } from '../../services/usuario/usuario.service';

@Injectable({
  providedIn: 'root',
})
/**
 * Guard usuarios de Riego
 */
export class RiegoUsersGuard implements CanActivate {

  constructor(
    private usuarioService: UsuarioService,
    public router: Router,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canAccessRiegoModule();
  }

  canAccessRiegoModule(): boolean {
    const allowedRoles = ['Regador', 'Supervisor de Riego'];

    if (allowedRoles.indexOf(this.usuarioService.getUserRole()) !== -1) {
      return true;
    }

    this.router.navigateByUrl('/solicitudes');
    return false;
  }
}
