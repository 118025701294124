<div id="side-menu">
  <div class="side-menu-top">
    <img class="logo" src="assets/images/logo-unagro.png" />
  </div>

  <div class="side-menu-user">
    <img class="profile-pic" src="assets/images/profile-placeholder.jpg" />

    <h1>{{getUserFullName()}}</h1>
    <p>{{getUserRole()}} | <b>Legajo: </b>{{getUserLegajo()}}</p>
  </div>

  <div class="side-menu-options">
    <div class="row">

      <div class="col">
        <mat-icon matBadge="{{unreadNotifications}}"
          matBadgeHidden="{{unreadNotifications === 0 || unreadNotifications === null}}" matBadgeColor="warn"
          matBadgeSize="small" class="clickeable opt" (click)="obtenerNotifications($event);">
          notifications_none
        </mat-icon>
      </div>

      <div class="col" *ngIf="userIsFcoria()">
        <mat-icon class="opt" matTooltip="Cambiar de Rol" (click)="switchFcoriaRole()">
          compare_arrows
        </mat-icon>
      </div>

      <div class="col">
        <mat-icon class="opt" matTooltip="Cerrar Sesión" (click)="logout()">
          power_settings_new
        </mat-icon>
      </div>

      <mat-icon class="close-notificacion" matTooltip="Cerrar Notificaciones" (click)="closeNotificationWrap()"
        [ngClass]="notificationSwitch ? 'd-block' : 'd-none'">
        highlight_off
      </mat-icon>
      <div id="notificationsWrap" [ngClass]="notificationSwitch ? 'd-block' : 'd-none'"
        (clickOutside)="closeNotificationWrap()">

        <h6 class="text-left">Notificaciones</h6>
        <hr />
        <ul>
          <li *ngFor="let item of notifications | slice:0:15;" [ngClass]="item?.fecha_lectura ? 'leida' : 'no-leida'">
            <small>{{item?.created_at | date:'dd/MM/yyyy - hh:mm a'}}</small><br>
            <span>{{item?.mensaje}}</span>
          </li>
          <li>
            <span class="no-more-notf">No hay más notificaciones...</span>
          </li>
        </ul>
      </div>

    </div>
  </div>

  <div class="side-menu-nav">
    <mat-accordion [displayMode]="'flat'">
      <mat-expansion-panel [expanded]="categoryIsActive('/solicitudes')" *ngIf="canSeeCategory('solicitudes')">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon class="expand-icon" [inline]="true">list_alt</mat-icon>
            Solicitudes
          </mat-panel-title>
        </mat-expansion-panel-header>

        <mat-list>
          <mat-list-item [routerLink]="['/solicitudes']" [class.active]="itemIsActive('/solicitudes')">
            <mat-icon mat-list-icon>vertical_split</mat-icon>
            <p mat-line>Ver Solicitudes</p>
          </mat-list-item>
        </mat-list>
      </mat-expansion-panel>

      <mat-expansion-panel [expanded]="categoryIsActive('/riego')" *ngIf="canSeeCategory('riego')">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon class="expand-icon" [inline]="true">spa</mat-icon>
            Riego
          </mat-panel-title>
        </mat-expansion-panel-header>

        <mat-list>
          <mat-list-item [routerLink]="['/riego/dashboard']" [class.active]="itemIsActive('/riego/dashboard')">
            <mat-icon mat-list-icon>dashboard</mat-icon>
            <p mat-line>Dashboard</p>
          </mat-list-item>
          <mat-list-item [routerLink]="['/riego/opriego']" [class.active]="itemIsActive('/riego/opriego')">
            <mat-icon mat-list-icon>playlist_add_check</mat-icon>
            <p mat-line>Operaciones de Riego</p>
          </mat-list-item>
          <mat-list-item [routerLink]="['/riego/bpyr']" [class.active]="itemIsActive('/riego/bpyr')">
            <mat-icon mat-list-icon>opacity</mat-icon>
            <p mat-line>Bombas, Pozos y Represas</p>
          </mat-list-item>
          <mat-list-item [routerLink]="['/riego/lotes']" [class.active]="itemIsActive('/riego/lotes')">
            <mat-icon mat-list-icon>map</mat-icon>
            <p mat-line>Campos, Lotes y Cuadros</p>
          </mat-list-item>
          <mat-list-item [routerLink]="['/riego/fertilizacion']" [class.active]="itemIsActive('/riego/fertilizacion')">
            <mat-icon mat-list-icon>invert_colors</mat-icon>
            <p mat-line>Fertilización</p>
          </mat-list-item>
          <mat-list-item [routerLink]="['/riego/regadores']" [class.active]="itemIsActive('/riego/regadores')">
            <mat-icon mat-list-icon>people</mat-icon>
            <p mat-line>Permisos para Regadores</p>
          </mat-list-item>
        </mat-list>
      </mat-expansion-panel>

      <mat-expansion-panel [expanded]="categoryIsActive('/configuracion')" *ngIf="canSeeCategory('configuracion')">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon class="expand-icon" [inline]="true">settings</mat-icon>
            Configuración
          </mat-panel-title>
        </mat-expansion-panel-header>

        <mat-list>
          <mat-list-item [routerLink]="['/configuracion/insumos']"
            [class.active]="itemIsActive('/configuracion/insumos')">
            <mat-icon mat-list-icon>shopping_cart</mat-icon>
            <p mat-line>Configurar Insumos</p>
          </mat-list-item>
          <mat-list-item [routerLink]="['/configuracion/usuarios']"
            [class.active]="itemIsActive('/configuracion/usuarios')">
            <mat-icon mat-list-icon>people</mat-icon>
            <p mat-line>Configurar Usuarios</p>
          </mat-list-item>
          <mat-list-item [routerLink]="['/configuracion/destinos']"
            [class.active]="itemIsActive('/configuracion/destinos')">
            <mat-icon mat-list-icon>location_on</mat-icon>
            <p mat-line>Configurar Destinos</p>
          </mat-list-item>
        </mat-list>
      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <div class="boton-que-hay-de-nuevo">
    <i class="material-icons" (click)="abrirNoticias()">
      info
    </i>
  </div>
</div>
