import { Component } from '@angular/core';
import { environment } from './../../../environments/environment';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-what-is-new-modal',
  templateUrl: './what-is-new-modal.component.html',
  styleUrls: ['./what-is-new-modal.component.scss']
})
export class WhatIsNewModalComponent {

  public version = environment.version;

  constructor(
    private dialogRef: MatDialogRef<WhatIsNewModalComponent>,
  ) { }

  public cerrar() {
    this.dialogRef.close();
  }
}
